(function () {
    'use strict';

    angular
        .module('core')
        .controller('loginCtrl', loginCtrl);

    loginCtrl.$inject = ['$http', '$scope', '$rootScope', '$element'];

    function loginCtrl($http, $scope, $rootScope, $element) {
        var vm = $scope;

        vm.pin = '';

        vm.pinEntry = function(_num){
            if(vm.pin.length < 6){
                vm.pinTouched = true;
                vm.pin = vm.pin + '' + _num;
            }
        }
        vm.pinBack = function(){
            var _chars = vm.pin.split("");
            _chars.pop();
            vm.pin = _chars.join("");
        }
    }
})();