(function () {
    'use strict';

    angular
        .module('core')
        .controller('weeklyCtrl', weeklyCtrl);

    weeklyCtrl.$inject = ['$http', '$scope', '$rootScope', '$element'];

    function weeklyCtrl($http, $scope, $rootScope, $element) {
        var vm = $scope;

        vm.employee = {}

        vm.getTotal = function(item){
            var total = 0;
            $(Object.keys(vm.employee)).each(function(){
                var key = this;
                var emp = vm.employee[key];
                if(emp[item] != undefined && !isNaN(emp[item]) && emp[item] > 0){
                    total = total + emp[item];
                }
                else if(item == 'cash'){
                    console.log(key, vm.getCash(key));
                    total = total + vm.getCash(key);
                }
            });

            return total;
        }

        vm.getCash = function(person){
            var _rate = vm.employee[person].rate != undefined ? vm.employee[person].rate : 0;
            var _hours = vm.employee[person].hours != undefined ? vm.employee[person].hours : 0;
            var _check = vm.employee[person].check != undefined ? vm.employee[person].check : 0;

            return (_rate * _hours) - _check;
        }
    }
})();