(function () {
    'use strict';

    angular
        .module('core')
        .controller('registerCtrl', registerCtrl);

    registerCtrl.$inject = ['$http', '$scope', '$rootScope', '$element'];

    function registerCtrl($http, $scope, $rootScope, $element) {
        $scope.washes = [];
        $scope.expenses = [];

        $scope.addWash = addWash;
        $scope.removeWash = removeWash;

        $scope.addExpense = addExpense;
        $scope.removeExpense = removeExpense;

        $scope.couponTypes = [
            {'label':'Select a source','value':''},
            {'label':'Email','value':'email'},
            {'label':'Web','value':'web'},
            {'label':'Print','value':'print'},
            {'label':'Facebook','value':'facebook'},
            {'label':'Other','value':'other'}
        ];

        function addWash(){
        	$scope.washes.push({});
        }
        function removeWash(_index){
        	$scope.washes.splice(_index, 1)
        }

        function addExpense(){
            $scope.expenses.push({});
        }
        function removeExpense(_index){
            $scope.expenses.splice(_index, 1)
        }
    }
})();