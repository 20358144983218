(function () {
    'use strict';

    angular
        .module('core')
        .controller('calCtrl', calCtrl);

    calCtrl.$inject = ['$http', '$scope', '$rootScope', '$element'];

    function calCtrl($http, $scope, $rootScope, $element) {
        $scope.weeklyDate = new Date();
        $scope.dailyDate = new Date();
        $scope.dates = [];

        $http.get('service/dates/get').
        then(function(response) {
            var data = response.data;
            $scope.dates = data;
        });
        
        $scope.onlyAvailable = onlyAvailable;
        $scope.onlySunday = onlySunday;
        $scope.onlySaturday = onlySaturday;

        function onlyAvailable(date){
            var isValid = false;
            $($scope.dates).each(function(){
                var _date = this.split('-');
                if(parseInt(_date[0]) == date.getFullYear()
                    && parseInt(_date[1]) == date.getMonth()+1
                    && parseInt(_date[2]) == date.getDate())
                {
                    isValid = true;
                }
            });
            return isValid;
        }

        function onlySunday(date){
            if(date.getDay() == 0){
                return true;
            }
            return false;
        }
        function onlySaturday(date){
            if(date.getDay() == 6){
                return true;
            }
            return false;
        }
    }
})();