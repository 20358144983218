(function () {
    'use strict';

    angular
        .module('core')
        .controller('employeesCtrl', employeesCtrl);

    employeesCtrl.$inject = ['$http', '$scope', '$rootScope', '$element'];

    function employeesCtrl($http, $scope, $rootScope, $element) {
        $scope.washes = [];
        $scope.employeeList = [];
        $scope.otherWashes = [
            {'label':'Details','value':'details'},
            {'label':'Police','value':'police'},
            {'label':'Enterprise','value':'enterprise'},
            {'label':'Gift Card','value':'gift card'},
            {'label':'Whites','value':'whites'},
            {'label':'Raincheck','value':'raincheck'},
            {'label':'Other','value':'other'}
        ];

        if($rootScope.employees == undefined){
            $rootScope.employees = [];
        }

        $scope.checkEmployees = checkEmployees;
        $scope.setEmployees = setEmployees;
        $scope.getEmployees = getEmployees;

        $scope.addWash = addWash;
        $scope.removeWash = removeWash;
        $scope.addEmployee = addEmployee;
        $scope.removeEmployee = removeEmployee;
        $scope.showHours = showHours;

        function checkEmployees(){
            var _checked = false;
            $($rootScope.employees).each(function(){
                if(this.checked){
                    _checked = true;
                }
            });
            return _checked;
        }


        function addWash(){
            $scope.washes.push({});
        }

        function removeWash(_index){
            $scope.washes.splice(_index, 1)
        }

        function addEmployee(){
            $rootScope.employees.push({'new':true, 'checked':true});
        }
        function removeEmployee(_index){
            $rootScope.employees.splice(_index, 1);
        }

        function showHours(_hours){
            var _floor = Math.floor(_hours);
            var _remain = _hours - _floor;
            var _min = Math.ceil(_remain * 60);
            var _total = _floor;
            if(_min != 0){
                _total = _total + ' ' + _min + 'min';
            }
            return _total;
        }

        function setEmployees(){
            $http.get('service/employees/get').
            then(function(response) {
                var data = response.data;
                $rootScope.employees = data;
                $scope.employeeList = [];
                $($rootScope.employees).each(function(){
                    if(this.id != null){
                        $scope.employeeList.push({'value':angular.copy(this.id), 'label':angular.copy(this.name)});
                    }
                    if(this.timein != null){
                        this.timein = new Date(1970, 0, 1, parseInt(this.timein.split(':')[0]), parseInt(this.timein.split(':')[1]), 0);
                    }
                    if(this.timeout != null){
                        this.timeout = new Date(1970, 0, 1, parseInt(this.timeout.split(':')[0]), parseInt(this.timeout.split(':')[1]), 0);
                    }
                });
                $scope.employeeList.push({'value':'other', 'label':'Other'});
            });
        }

        function getEmployees(){
            $http.get('service/employees/get').
            then(function(response) {
                var data = response.data;

                $scope.employeeList = [];
                $(data).each(function(){
                    if(this.id != null){
                        $scope.employeeList.push({'value':angular.copy(this.id), 'label':angular.copy(this.name)});
                    }
                    if(this.timein != null){
                        this.timein = new Date(1970, 0, 1, parseInt(this.timein.split(':')[0]), parseInt(this.timein.split(':')[1]), 0);
                    }
                    if(this.timeout != null){
                        this.timeout = new Date(1970, 0, 1, parseInt(this.timeout.split(':')[0]), parseInt(this.timeout.split(':')[1]), 0);
                    }
                });
                $scope.employeeList.push({'value':'other', 'label':'Other'});
            });
        }
    }
})();