'use strict';

angular.module('angular-parallax', [
]).directive('parallax', ['$window', function($window) {
	return {
    	restrict: 'A',
    	scope: {
    		parallaxSpeed: '@',
    		parallaxRatio: '@',
    		parallaxVerticalOffset: '@',
    		parallaxHorizontalOffset: '@'
    	},
    	link: function($scope, elem, attrs) {
	    	var setPosition = function () {
	    		var maxSpeed = 1;
	    		var minSpeed = -1;
                if($scope.parallaxRatio == undefined){
                    $scope.parallaxRatio = 1;
                }
	    		if($scope.parallaxSpeed == undefined){
	    			$scope.parallaxSpeed = maxSpeed;
	    		}
	    		if($scope.parallaxSpeed > maxSpeed){
	    			$scope.parallaxSpeed = maxSpeed;
	    		}
	    		if($scope.parallaxSpeed < minSpeed){
	    			$scope.parallaxSpeed = minSpeed;
	    		}

	    		var top = (($window.innerHeight + $window.pageYOffset) - $(elem).offset().top);
		    	top = top >= 0 ? top : 0;

		      	var bot = $window.innerHeight + $(elem).outerHeight();
	    		var pct = (top/bot) * 100 * ($scope.parallaxSpeed/1) * $scope.parallaxRatio;

	    		elem.css('transform', 'translateY(' + (50 - pct) + '%)');
			};

			setPosition();

			angular.element($window).bind("scroll", setPosition);
			angular.element($window).bind("touchmove", setPosition);
	    }  // link function
	};
}]).directive('parallaxBackground', ['$window', function($window) {
	return {
    	restrict: 'A',
    	scope: {
    		parallaxRatio: '@',
    		parallaxVerticalOffset: '@',
    	},
    	link: function($scope, elem, attrs) {
    		var setPosition = function () {
    			var top = (($window.innerHeight + $window.pageYOffset) - $(elem).position().top);
    			top = top >= 0 ? top : 0;

    			var bot = $window.innerHeight + $(elem).outerHeight();
    			var pct = (top/bot)*100;

    			elem.css('background-position', "50% " + pct + "%");
    		};

    		// set our initial position - fixes webkit background render bug
    		angular.element($window).bind('load', function(e) {
    			setPosition();
    			$scope.$apply();
    		});

    		angular.element($window).bind("scroll", setPosition);
    		angular.element($window).bind("touchmove", setPosition);
    	}  // link function
    };
}]);