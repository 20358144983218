(function () {
    'use strict';

    angular
        .module('core')
        .controller('trendCtrl', trendCtrl);

    trendCtrl.$inject = ['$http', '$scope', '$rootScope', '$element', '$timeout'];

    var vh = window.innerHeight/100;
	var vw = window.innerWidth/100;
	var em;

	function calcEm(){
		try{
			em = parseFloat(getComputedStyle($('nvd3')[0]).fontSize);
		} catch(e){
			em = 16;
		}

		if(Number.isNaN(em)){
			em = 16;
		}
	}

	calcEm();

    function trendCtrl($http, $scope, $rootScope, $element, $timeout) {
        var vm = $scope;

        vm.annual = false;
        var d = new Date();
        $rootScope.annualYear = d.getFullYear();

        vm.trendValue = 'total_cars';

        vm.show = {}

        $(window).on('resize', function(){
			$timeout(function(){
				vh = window.innerHeight/100;
				vw = window.innerWidth/100;

				$('.chart').css('height', 'calc(100% - '+$('header').outerHeight()+'px');

				calcEm();

				vm.chart.options.chart.margin = {
                	top: 2*em,
                    right: 1.5*em,
                    bottom: 2.5*em,
                    left: 3*em
                }

				$timeout(function(){
					vm.fixChart();
				}, vm.duration*2);
			}, 50);
		});

        vm.duration = 500;
        vm.d3 = d3;

        var tooltip = {
			contentGenerator: function(d) {
				if(d.data != undefined){
					var _div = $('<table/>');
					var _date = d3.time.format('%b %e, %Y')(new Date(d.value));
					var _extra = '';
					var _decimal = (parseFloat(d.data.value) % 1);
					if(_decimal != 0){
						_decimal = _decimal.toString().length;
						if(_decimal > 2){
							_decimal = 2;
						}
						if(_decimal == 0){
							_decimal = '';
						}
					}
					else {
						_decimal = '';
					}

					_div.append('<tr><th>'+_date+'</th></tr>');
					_div.append('<tr class="font-3"><td class="a_center"><b>'+d3.format(',.'+_decimal+'f')(d.data.value)+'</b></td></tr>');

					return '<table>'+_div.html()+'</table>';
				}
				else {
					return '';
				}
			}
		}

        vm.chart = {
        	options: {
				chart:{
					duration: vm.duration,
	                margin: {
	                	top: 2*em,
	                    right: 1.5*em,
	                    bottom: 3.5*em,
	                    left: 2*em
	                },
					x: function(d){var _date = new Date(d.label); return _date;},
					y: function(d){return d.value;},
					xAxis: {
						showMaxMin: true,
						tickFormat: function(d){
              if(vm.annual){
                return d3.time.format("%b")(new Date(d));
              }
              else {
                return d3.time.format("%e")(new Date(d));
              }
						}
					},
					yAxis: {
						tickFormat: function(d){
							return d3.format(',f')(d);
						}
					},
					showControls: false,
					showValues: false,
					showLabels: false,
					showLegend: false,
					colors:[
						"#ffcc33","#AA093C","#DB2C6F","#EA52B3","#F978F8","#BD46CC","#8214A0","#7157D9","#015AC8","#0A96D2","#13D2DC","#00B3A4","#3CCF87","#0AB45A","#9BBF30","#CDC61B","#D99E0B","#96622D","#CA791D","#FF910D","#FA7850","#D95D03"
					],
	               	type: 'multiBarChart',
					tooltip: tooltip

	            }
	        },
	        data: []
	    }

	    vm.$watch('trendValue', function(newVal, oldVal) {
	    	if(newVal != undefined){
	    		vm.loading = true;
	    		vm.updateData();
	    	}
	    })

	    $rootScope.$watch('date', function(newVal, oldVal) {
	    	if(newVal != undefined){
	    		vm.loading = true;
	    		vm.updateData();
	    	}
	    });

	    vm.updateData = function(){
	    	if($rootScope.date.start != undefined){
	    		var _start = $rootScope.date.start.month + '-01-' + $rootScope.date.start.year;

	    		if($rootScope.date.end != undefined) {
	    			var _end = $rootScope.date.end.month + '-01-' + $rootScope.date.end.year;
	    		}
	    		else {
	    			var _end = _start;
	    		}

          if(vm.annual){
            _start = $rootScope.annualYear;
            _end = $rootScope.annualYear;
          }

	    		$http.get('services/trend.php?start=' + _start + '&end='+_end + '&value=' + vm.trendValue + '&annual=' + vm.annual.toString()).
		        then(function(response) {
		            var data = response.data;
		            var _high = 0;

		            $(data).each(function(){
		            	$(this.values).each(function(){
		            		if(this.value > _high){
			            		_high = this.value;
			            	}
		            	});
		            });

		            _high = Math.ceil((_high + (_high*.2))/10)*10;
		            console.log(_high);

		            vm.chart.data = data;
		            vm.chart.options.chart.forceY = [0,_high];

		            $timeout(function(){
		            	vm.fixChart();
		            	vm.loading = false;
		            }, vm.duration*2);
		        });
	    	}
	    }

	    vm.isMonthSelected = function(_side, _month){
			var _selected = false;

			var _start = (new Date(vm.dateCopy.start.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+1)).getTime();
			var _end;
			if(vm.dateCopy.end != undefined){
				var _end = (new Date(vm.dateCopy.end.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.end.month)+1, 0)).getTime();
			}

			var _curStart = (new Date(vm.dateCopy[_side], findInArray(vm.dateCopy.months, 'name', _month.name)+1)).getTime();
			var _curEnd = (new Date(vm.dateCopy[_side], findInArray(vm.dateCopy.months, 'name', _month.name)+1, 0)).getTime();

			if((_end == undefined && _curStart == _start) || (_curStart >= _start && _curEnd <= _end)){
				_selected = true;
			}
			return _selected;
		}
		vm.isMonthFirst = function(_side, _month){
			var _selected = false;

			var _start = (new Date(vm.dateCopy.start.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+1)).getTime();

			var _curStart = (new Date(vm.dateCopy[_side], findInArray(vm.dateCopy.months, 'name', _month.name)+1)).getTime();
			var _curEnd = (new Date(vm.dateCopy[_side], findInArray(vm.dateCopy.months, 'name', _month.name)+1, 0)).getTime();

			if(_curStart == _start){
				_selected = true;
			}
			return _selected;
		}
		vm.isMonthLast = function(_side, _month){
			var _selected = false;

			var _start = (new Date(vm.dateCopy.start.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+1)).getTime();
			var _end;
			if(vm.dateCopy.end != undefined){
				var _end = (new Date(vm.dateCopy.end.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.end.month)+1, 0)).getTime();
			}

			var _curStart = (new Date(vm.dateCopy[_side], findInArray(vm.dateCopy.months, 'name', _month.name)+1)).getTime();
			var _curEnd = (new Date(vm.dateCopy[_side], findInArray(vm.dateCopy.months, 'name', _month.name)+1, 0)).getTime();

			if((_end == undefined && _start == _curStart) || _curEnd == _end){
				_selected = true;
			}
			return _selected;
		}
		vm.isQuarterSelected = function(_side, _quarter){
			var _selected = false;

			var _start = (new Date(vm.dateCopy.start.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+1)).getTime();
			var _end;
			if(vm.dateCopy.end != undefined){
				var _end = (new Date(vm.dateCopy.end.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.end.month)+1, 0)).getTime();
			}

			var _monStart = getQuarterStart(_quarter);
			var _monEnd = getQuarterEnd(_quarter);

			var _curStart = (new Date(vm.dateCopy[_side], _monStart+1)).getTime();
			var _curEnd = (new Date(vm.dateCopy[_side], _monEnd+1, 0)).getTime();

			if((_end == undefined && _curStart == _start) || (_curStart >= _start && _curEnd <= _end)){
				_selected = true;
			}
			return _selected;
		}
		vm.isQuarterFirst = function(_side, _quarter){
			var _selected = false;

			var _start = (new Date(vm.dateCopy.start.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+1)).getTime();

			var _monStart = getQuarterStart(_quarter);
			var _monEnd = getQuarterEnd(_quarter);

			var _curStart = (new Date(vm.dateCopy[_side], _monStart+1)).getTime();
			var _curEnd = (new Date(vm.dateCopy[_side], _monEnd+1, 0)).getTime();

			if(_curStart == _start){
				_selected = true;
			}
			return _selected;
		}
		vm.isQuarterLast = function(_side, _quarter){
			var _selected = false;

			var _start = (new Date(vm.dateCopy.start.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+1)).getTime();
			var _end;
			if(vm.dateCopy.end != undefined){
				var _end = (new Date(vm.dateCopy.end.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.end.month)+1, 0)).getTime();
			}

			var _monStart = getQuarterStart(_quarter);
			var _monEnd = getQuarterEnd(_quarter);

			var _curStart = (new Date(vm.dateCopy[_side], _monStart+1)).getTime();
			var _curEnd = (new Date(vm.dateCopy[_side], _monEnd+1, 0)).getTime();

			if((_end == undefined && _start == _curStart) || _curEnd == _end){
				_selected = true;
			}
			return _selected;
		}

		function getQuarterStart(_quarter){
			var _return;
			switch(_quarter.name){
				case 'Q1':
				_return = 0;
				break;

				case 'Q2':
				_return = 3;
				break;

				case 'Q3':
				_return = 6;
				break;

				case 'Q4':
				_return = 9;
				break;
			}
			return _return;
		}

		function getQuarterEnd(_quarter){
			var _return;
			switch(_quarter.name){
				case 'Q1':
				_return = 2;
				break;

				case 'Q2':
				_return = 5;
				break;

				case 'Q3':
				_return = 8;
				break;

				case 'Q4':
				_return = 11;
				break;
			}
			return _return;
		}

		$rootScope.dateFix = function(_year){
        var _return;
        if(_year != undefined){
            _return = "'" + _year.toString().substring(2);
        }
        return _return;
    }

    var d = new Date();

    var years = [];
    years.push({name: 2016});
    while(years[years.length - 1].name < d.getFullYear()){
      years.push({name: years[years.length - 1].name + 1});
    }

    vm.years = years;

		$rootScope.date = {
			left: (d.getFullYear()-1),
			right: d.getFullYear(),
			toggle: 'months',
			months: [
				{name:'Jan'}, {name:'Feb'}, {name:'Mar'}, {name:'Apr'}, {name:'May'}, {name:'Jun'}, {name:'Jul'}, {name:'Aug'}, {name:'Sep'}, {name:'Oct'}, {name:'Nov'},{name:'Dec'}
			],
			years: years,
			options: [
				{
					title: '13 Months',
					value: 'l13'
				},{
					title: 'Q1',
					value: 'q1'
				},{
					title: 'Q2',
					value: 'q2'
				},{
					title: 'Q3',
					value: 'q3'
				},{
					title: 'Q4',
					value: 'q4'
				},{
					title: 'This Week',
					value: 'tw'
				},{
					title: 'Last Week',
					value: 'lw'
				}
			]
		}

		$rootScope.date.start = {
			year: d.getFullYear(),
			month: $rootScope.date.months[d.getMonth()].name
		}

		vm.checkDate = function(_side, _item){
			var _selected;
			var _type;

			$(vm.dateCopy.quarters).each(function(){
				if(this.name == _item.name){
					_selected = this;
					_type = 'quarter';
				}
			});
			if(_selected == undefined){
				$(vm.dateCopy.months).each(function(){
					if(this.name == _item.name){
						_selected = this;
						_type = 'month';
					}
				});
			}

			if(vm.dateCopy.start != undefined && vm.dateCopy.end != undefined){
				delete vm.dateCopy.end;

				vm.dateCopy.start = {
					'year':vm.dateCopy[_side]
				}

				switch(_type){
					case 'month':
					vm.dateCopy.start.month = _selected.name;
					break;

					case 'quarter':
					vm.dateCopy.start.month = $rootScope.date.months[getQuarterStart(_selected)].name;
					break;
				}
			}
			else if(vm.dateCopy.end == undefined){
				var _start = (new Date(vm.dateCopy.start.year, findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+1)).getTime();

				switch(_type){
					case 'month':
					var _curStart = (new Date(vm.dateCopy[_side], findInArray(vm.dateCopy.months, 'name', _selected.name)+1)).getTime();

					if(_curStart < _start) {
						vm.dateCopy.end = angular.copy(vm.dateCopy.start);
						vm.dateCopy.start = {
							'year':vm.dateCopy[_side],
							'month':_selected.name
						}
					}
					else if(_curStart != _start){
						vm.dateCopy.end = {
							'year':vm.dateCopy[_side],
							'month':_selected.name
						}
					}
					break;

					case 'quarter':
					var _curStart = (new Date(vm.dateCopy[_side], getQuarterStart(_selected)+1)).getTime();
					if(_curStart < _start){
						vm.dateCopy.end = {
							'year':vm.dateCopy.start.year,
							'month':vm.dateCopy.months[findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+2].name
						}
						vm.dateCopy.start = {
							'year':vm.dateCopy[_side],
							'month':$rootScope.date.months[getQuarterStart(_selected)].name
						}
					}
					else if(_curStart != _start){
						vm.dateCopy.end = {
							'year':vm.dateCopy[_side],
							'month':$rootScope.date.months[getQuarterEnd(_selected)].name
						}
					}
					break;
				}
			}
		}

		vm.fixChart = function(){
			var _temp = {};

			d3.selectAll('.nv-multibar .nv-group:not(:empty)').each(function(group, index){
				var g = d3.select(this);
				// Remove previous labels if there is any
				g.selectAll('text.bar-total').remove();
				var last = {
					y: 0,
					height: 0,
					width: 0
				}
				g.selectAll('.nv-bar:not(.forecast)').each(function(bar){
					var b = d3.select(this);
					var barWidth = b.attr('width');
					var barHeight = b.attr('height');

					if(_temp[bar.label] == undefined){
						_temp[bar.label] = {
							top: parseFloat(b.attr('y')) - 5,
							value: bar.value
						}
					} else {
						_temp[bar.label].top = parseFloat(b.attr('y')) - 10,
						_temp[bar.label].value = _temp[bar.label].value + bar.y;
					}

					if(parseFloat(b.attr('y')) > last.y) {
						last.y = parseFloat(b.attr('y'));
						last.x = parseFloat(b.attr('x'));
						last.width = barWidth;
						last.transform = b.attr('transform');
						last.label = bar.label;
					}

					if(index == ($('.nv-multibar .nv-group:not(:empty)').length - 1)){

						g.append('text')
						// Transforms shift the origin point then the x and y of the bar
						// is altered by this transform. In order to align the labels
						// we need to apply this transform to those.
						.attr('transform', b.attr('transform'))
						.text(function(){
							var _decimal = (parseFloat(_temp[bar.label].value) % 1);
							if(_decimal != 0){
								_decimal = _decimal.toString().length;
								if(_decimal > 2){
									_decimal = 2;
								}
								if(_decimal == 0){
									_decimal = '';
								}
							}

							var _format = ','+_decimal+'f';
							return d3.format(_format)(_temp[bar.label].value);
						})
						.attr('y', function(){
							// Center label vertically
							var height = this.getBBox().height;
							return parseFloat(b.attr('y')) - 10; // 10 is the label's margin from the bar
						})
						.attr('x', function(){
							// Center label horizontally
							var width = this.getBBox().width;
							return parseFloat(b.attr('x')) + (parseFloat(barWidth) / 2) - (width / 2);
						})
						.attr('class', 'bar-total');
					}
				});
			});
		}

		vm.showDate = function($event, showTemplate){
			vm.show.date = true;
			vm.show.overlay = true;
			vm.dateCopy = angular.copy($rootScope.date);
		}
		vm.setDate = function(){
			if(vm.dateCopy.toggle == 'quarters' && vm.dateCopy.end == undefined){
				vm.dateCopy.end = {
					'year':vm.dateCopy.start.year,
					'month':vm.dateCopy.months[findInArray(vm.dateCopy.months, 'name', vm.dateCopy.start.month)+2].name
				}
			}

			$rootScope.date = angular.copy(vm.dateCopy);
			vm.show.date = false;
			vm.show.overlay = false;
		}

		$(window).trigger('resize');
    }
})();
