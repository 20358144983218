(function($){
    $(document).on('ready', function(){
        
        $(window).on('resize', function(){
            
        });
        
        $(window).trigger('resize');
    });
})(jQuery, this);

function findInArray(array, name, value){
    var _item;
    $(array).each(function(index){
        if(this[name] == value){
            _item = index
        }
    });
    return _item;
}

function existsIn(_arr, _name, _val){
    var _exist = false;
    $(_arr).each(function(){
        if(this[_name] != undefined && this[_name] == _val){
            _exist = true;
        }
    });
    return _exist;
}

function compare(obj1, obj2){
    return JSON.stringify(obj1) == JSON.stringify(obj2);
}

Number.prototype.fixHours = function(){
    var _hours = Math.floor(this);
    var _remain = this - _hours;
    var _min = Math.ceil(_remain * 60);
    var _total = _hours+'h';
    if(_min != 0){
        _total = _total + ' ' + _min + 'm';
    }
    return _total;
}